import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

// components
import { BaseContainer, BaseGrid, BaseColumn } from '@/components/primitives';
import { BaseButton } from '@/components/partials';
import { BaseForm, BaseInput } from '@/components/partials/forms';

// types
import type { ComputedRef } from 'vue';
import type { WopsUserInterface } from '@/classes/user';
import type { UnexpectedItemForm } from '@/types/form';
import { HelperFormErrors } from '@/helpers/form';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const store = useStore();
const route = useRoute();

const user: ComputedRef<WopsUserInterface> = computed(() => store.state.returnOrder.user);

const unexpectedItemForm: UnexpectedItemForm = reactive({
  comment: '',
  images: null,
  errors: new HelperFormErrors(),
});

const showImages = (images: File[] | null): void => {
  unexpectedItemForm.images = images;
};

const submitUnexpectedItemForm = async (): Promise<void> => {
  await store.dispatch('returnOrder/submitUnexpectedItemForm', unexpectedItemForm);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseContainer), null, {
    default: _withCtx(() => [
      _createVNode(_unref(BaseForm), { onFormSubmitted: submitUnexpectedItemForm }, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseGrid), null, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseColumn), { span: { tablet: '6' } }, {
                default: _withCtx(() => [
                  _createVNode(_unref(BaseInput), {
                    label: "Enter item's description, that will be sent to Customer Service",
                    name: "comment",
                    type: "textarea",
                    value: unexpectedItemForm.comment,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((unexpectedItemForm.comment) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_unref(BaseColumn), { span: { tablet: '6' } }, {
                default: _withCtx(() => [
                  _createVNode(_unref(BaseInput), {
                    label: "images",
                    name: "images",
                    type: "file",
                    accept: "image/*",
                    "onUpdate:image": showImages
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(BaseColumn), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(BaseButton), {
                    color: "warning",
                    type: "submit",
                    ref: "submit-button"
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Submit")
                    ])),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})